<template>
  <div
    class="container pt-5 pb-5 d-flex justify-content-center"
    style=""
    id="registration"
    v-if="jwtAccessValid() && userProfile.checked"
  >
    <div class="shadow bg-body rounder p-4">
      <h3 class="text-center mb-3">Профиль пользователя</h3>
      <div class="mb-3 row">
        <label for="fullname" class="col-sm-3 col-form-label">ФИО</label>
        <div class="col-sm-9">
          <input
            id="fullname"
            class="form-control"
            v-model="userProfile.fullname"
            type="text"
            required
            placeholder=""
            @input="status.success = true"
            disabled
          />
        </div>
      </div>

      <div class="mb-3 row">
        <label for="phone" class="col-sm-3 col-form-label">Телефон</label>
        <div class="col-sm-9">
          <div class="input-group">
            <span class="input-group-text" id="basic-addon1">+7</span>
            <input
              id="phone"
              class="form-control"
              :value="userProfile.phone ? userProfile.phone.slice(2, 12) : ''"
              type="tel"
              pattern="[0-9]{10}"
              required
              placeholder="1234567890"
              @input="status.success = true"
              disabled
            />
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label for="email" class="col-sm-3 col-form-label">Email</label>
        <div class="col-sm-9">
          <input
            id="input-email"
            class="form-control"
            v-model="userProfile.email"
            type="email"
            required
            placeholder=""
            @input="status.success = true"
            disabled
          />
        </div>
      </div>

      <div class="mb-3 row">
        <label for="input-position" class="col-sm-3 col-form-label"
          >Должность</label
        >
        <div class="col-sm-9">
          <input
            id="input-position"
            class="form-control"
            v-model="userProfile.position"
            type="email"
            required
            placeholder=""
            @input="status.success = true"
          />
        </div>
      </div>

      <div class="mb-3 row">
        <label for="permissions" class="col-sm-3 col-form-label"
          >Права доступа</label
        >

        <div class="col-sm">
          <div class="row">
            <div class="col-sm">
              <small class="text-muted">Организация</small>
            </div>
            <div class="col-sm">
              <small class="text-muted">Роль</small>
            </div>
          </div>
          <div
            v-for="permission in userProfile.permissions"
            :key="permission.permissionId"
            class="row mb-2"
          >
            <div class="col-sm">
              <span v-if="permission.client">
                {{ permission.client.fullname }}
              </span>
            </div>
            <div class="col-sm">
              {{ permission.role.label }}
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="postrelay" class="col-sm-3 col-form-label"
          >Почтовые рассылки</label
        >

        <div class="col-sm">
          <div
            v-for="(relay, index) in postRelays"
            :key="index"
            class="row mb-2"
          >
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                :id="`flexSwitch-relay-${index}`"
                v-model="relay.relay"
              />
              <label class="form-check-label" :for="`flexSwitch-relay-${index}`"
                ><small>{{ relay.label }}</small></label
              >
            </div>
          </div>
        </div>
      </div>

      <div class="mb-1 row">
        <a
          :href="`http://t.me/pcsChatBot?start=lkpcsuserid_${userProfile._id}`"
          class="btn btn-link"
        >
          <img class="img-fluid1" width="50" src="@/assets/logo-tg.png" />
          Подключить Telegram бота
        </a>
      </div>

      <div class="mb-3 row">
        <div class="col-sm-9 w-100">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchPassword"
              v-model="setPassword"
            />
            <label class="form-check-label" for="flexSwitchPassword"
              ><small>Задать новый пароль</small></label
            >
          </div>
          <div>
            <form @submit="changePasswordPosition($event)">
              <input
                id="input-password"
                class="form-control"
                v-model="newPassword"
                type="text"
                required
                placeholder=""
                minlength="7"
                v-if="setPassword"
              />
              <button typy="submit" class="btn btn-primary w-100 mt-3">
                Сохранить
              </button>
            </form>
          </div>
        </div>
      </div>

      <div class="row" style="max-width: 800px">
        <div class="col-sm">
          <p>
            * Нажимая на кнопку, я даю согласие на обработку персональных данных
            и соглашаюсь c
            <router-link
              class="btn-link text-muted"
              aria-current="page"
              :to="{ name: 'privacy' }"
              >политикой конфиденциальности.</router-link
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import useCommonMixin from "@/components/commonMixin.js";
import { ref } from "vue";
import { inject } from "vue";

export default {
  name: "Singup",
  setup() {
    const store = useStore();
    useCommonMixin();
    const { userProfile, jwtAccessValid } = useCommonMixin();
    const setPassword = ref(false);
    const newPassword = ref("");
    const warningBus = inject("warningBus");
    const postRelays = ref([
      { label: "Полезные новости от компании", name: "news", relay: true },
      {
        label:
          "Уведомления от ПРОФКЕЙС.Личный кабинет (смена статусов, заявок и т.п.)",
        name: "notify",
        relay: true,
      },
    ]);

    const changePassword = async (event) => {
      event.preventDefault();
      let response = await store.dispatch("changePassword", newPassword.value);
      if (response.status) {
        warningBus.emit("show", [`Пароль успешно изменен.`]);
        setPassword.value = false;
        await store.dispatch("FETCHuserProfile", { userId: null, phone: null });
      } else {
        warningBus.emit("show", [`Ошибка при смене пароля.`, response.data]);
      }
    };

    const changePasswordPosition = async (event) => {
      event.preventDefault();
      let msgs = [];
      let response = await store.dispatch(
        "changePosition",
        userProfile.value.position
      );
      if (response.status) {
        msgs.push(`Должность успешно изменена.`);
        await store.dispatch("FETCHuserProfile", {
          userId: null,
          phone: null,
        });
      } else {
        msgs.push(`Ошибка при смене должности.`);
        msgs.push(response.data);
      }
      console.log("setPassword.value", setPassword.value);
      if (setPassword.value) {
        console.log("setPassword.value", setPassword.value);
        let response = await store.dispatch(
          "changePassword",
          newPassword.value
        );
        if (response.status) {
          msgs.push(`Пароль успешно изменен.`);
          setPassword.value = false;
          await store.dispatch("FETCHuserProfile", {
            userId: null,
            phone: null,
          });
        } else {
          msgs.push(`Ошибка при смене пароля.`);
          msgs.push(response.data);
        }
      }

      warningBus.emit("show", msgs);
    };

    return {
      userProfile,
      setPassword,
      newPassword,
      changePassword,
      jwtAccessValid,
      changePasswordPosition,
      postRelays,
      store,
    };
  },
};
</script>
