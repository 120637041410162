<template>
  <div class="container pt-5 pb-5">
    <h3>Политики в отношении обработки персональных данных</h3>

    <div class="container">
      <div lang="RU" link="blue" vlink="#954F72" style="tab-interval: 35.4pt">
        <div class="WordSection1">
          <h5 style="margin-top: 6pt">
            <span
              style="
                font-size: 12pt;
                line-height: 107%;
                font-family: 'Calibri', sans-serif;
                mso-ascii-theme-font: minor-latin;
                mso-hansi-theme-font: minor-latin;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
              "
              >Пользователь, заполняя форму обратной связи&nbsp;на
              интернет-сайте&nbsp;https://</span
            ><span class="SpellE"
              ><span
                lang="EN-US"
                style="
                  font-size: 12pt;
                  line-height: 107%;
                  font-family: 'Calibri', sans-serif;
                  mso-ascii-theme-font: minor-latin;
                  mso-hansi-theme-font: minor-latin;
                  mso-bidi-theme-font: minor-latin;
                  color: black;
                  mso-themecolor: text1;
                  mso-ansi-language: EN-US;
                "
                >lk</span
              ></span
            ><span
              style="
                font-size: 12pt;
                line-height: 107%;
                font-family: 'Calibri', sans-serif;
                mso-ascii-theme-font: minor-latin;
                mso-hansi-theme-font: minor-latin;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
              "
              >.pcs.ru, принимает настоящее Согласие на обработку персональных
              данных (далее - Согласие). Действуя свободно, своей волей и в
              своем интересе, а также подтверждая свою дееспособность,
              Пользователь дает свое согласие группе компаний под рекламным
              названием ПРОФКЕЙС — ООО "Фирма Компьютер Сервис" (ИНН
              1001141729), ИП <span class="SpellE">Мишков</span> Вадим Борисович
              (ИНН 100110705285), ИП Макаров Илья Владимирович
              (ИНН&nbsp;100100219053), которая&nbsp;расположена&nbsp;по адресу
              185035, г. Петрозаводск, ул. Красная 10, на обработку своих
              персональных данных со следующими условиями:<o:p></o:p
            ></span>
          </h5>

          <p class="MsoNormal">
            <span style="color: black; mso-themecolor: text1"
              ><o:p>&nbsp;</o:p></span
            >
          </p>

          <h5>
            <span
              style="
                font-size: 12pt;
                line-height: 107%;
                font-family: 'Calibri', sans-serif;
                mso-ascii-theme-font: minor-latin;
                mso-hansi-theme-font: minor-latin;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
              "
              >1.Данное Согласие дается на обработку персональных данных, как
              без использования средств автоматизации, так и с их
              использованием.<o:p></o:p
            ></span>
          </h5>

          <h5>
            <span
              style="
                font-size: 12pt;
                line-height: 107%;
                font-family: 'Calibri', sans-serif;
                mso-ascii-theme-font: minor-latin;
                mso-hansi-theme-font: minor-latin;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
              "
              >2. Согласие дается на обработку следующих моих персональных
              данных: Персональные данные, не являющиеся специальными или
              биометрическими: номера контактных телефонов; адреса электронной"
              почты; место работы и занимаемая должность; пользовательские
              данные (сведения о местоположении; тип и версия ОС; тип и версия
              Браузера; тип устройства и разрешение его экрана; источник откуда
              пришел на сайт пользователь; с какого сайта или по какой рекламе;
              язык ОС и Браузера; какие страницы открывает и на какие кнопки
              нажимает пользователь; <span class="SpellE">ip</span>-адрес.<o:p
              ></o:p
            ></span>
          </h5>

          <h5>
            <span
              style="
                font-size: 12pt;
                line-height: 107%;
                font-family: 'Calibri', sans-serif;
                mso-ascii-theme-font: minor-latin;
                mso-hansi-theme-font: minor-latin;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
              "
              >3. Персональные данные не являются общедоступными.<o:p></o:p
            ></span>
          </h5>

          <p class="MsoNormal" style="margin-bottom: 18pt; line-height: normal">
            <span
              style="
                font-size: 12pt;
                mso-bidi-font-family: Calibri;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
              "
              >4. Цель обработки персональных данных: <o:p></o:p
            ></span>
          </p>

          <p
            class="MsoListParagraphCxSpFirst ms-4"
            style="
              margin-bottom: 18pt;
              mso-add-space: auto;
              text-indent: -18pt;
              line-height: normal;
              mso-list: l1 level1 lfo3;
            "
          >
            <!--[if !supportLists]--><span
              style="
                font-size: 12pt;
                font-family: Symbol;
                mso-fareast-font-family: Symbol;
                mso-bidi-font-family: Symbol;
                color: #212529;
                mso-fareast-language: RU;
              "
              ><span style="mso-list: Ignore"
                >·<span style="font: 7pt 'Times New Roman'"
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span
              ></span
            ><!--[endif]--><span
              style="
                font-size: 12pt;
                mso-bidi-font-family: Calibri;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
              "
              >обработка входящих запросов Пользователя с целью оказания
              консультирования; </span
            ><span
              style="
                font-size: 12pt;
                font-family: 'Arial', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
                color: #212529;
                mso-fareast-language: RU;
              "
              ><o:p></o:p
            ></span>
          </p>

          <p
            class="MsoListParagraphCxSpMiddle ms-4"
            style="
              margin-bottom: 18pt;
              mso-add-space: auto;
              text-indent: -18pt;
              line-height: normal;
              mso-list: l1 level1 lfo3;
            "
          >
            <!--[if !supportLists]--><span
              style="
                font-size: 12pt;
                font-family: Symbol;
                mso-fareast-font-family: Symbol;
                mso-bidi-font-family: Symbol;
                color: #212529;
                mso-fareast-language: RU;
              "
              ><span style="mso-list: Ignore"
                >·<span style="font: 7pt 'Times New Roman'"
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span
              ></span
            ><!--[endif]--><span
              style="
                font-size: 12pt;
                mso-bidi-font-family: Calibri;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
              "
              >аналитики действий Пользователя на веб-сайте и функционирования
              веб-сайта; </span
            ><span
              style="
                font-size: 12pt;
                font-family: 'Arial', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
                color: #212529;
                mso-fareast-language: RU;
              "
              ><o:p></o:p
            ></span>
          </p>

          <p
            class="MsoListParagraphCxSpMiddle ms-4"
            style="
              margin-bottom: 18pt;
              mso-add-space: auto;
              text-indent: -18pt;
              line-height: normal;
              mso-list: l1 level1 lfo3;
            "
          >
            <!--[if !supportLists]--><span
              style="
                font-size: 12pt;
                font-family: Symbol;
                mso-fareast-font-family: Symbol;
                mso-bidi-font-family: Symbol;
                color: #212529;
                mso-fareast-language: RU;
              "
              ><span style="mso-list: Ignore"
                >·<span style="font: 7pt 'Times New Roman'"
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span
              ></span
            ><!--[endif]--><span
              style="
                font-size: 12pt;
                mso-bidi-font-family: Calibri;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
              "
              >предоставление доступа Пользователю к сервисам, информации и/или
              материалам, содержащимся на веб-сайте </span
            ><span
              style="
                font-size: 12pt;
                font-family: 'Arial', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
                color: #212529;
                mso-fareast-language: RU;
              "
              ><o:p></o:p
            ></span>
          </p>

          <p
            class="MsoListParagraphCxSpMiddle ms-4"
            style="
              margin-bottom: 18pt;
              mso-add-space: auto;
              text-indent: -18pt;
              line-height: normal;
              mso-list: l1 level1 lfo3;
            "
          >
            <!--[if !supportLists]--><span
              style="
                font-size: 12pt;
                font-family: Symbol;
                mso-fareast-font-family: Symbol;
                mso-bidi-font-family: Symbol;
                color: #212529;
                mso-fareast-language: RU;
              "
              ><span style="mso-list: Ignore"
                >·<span style="font: 7pt 'Times New Roman'"
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span
              ></span
            ><!--[endif]--><span
              style="
                font-size: 12pt;
                mso-bidi-font-family: Calibri;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
              "
              >уточнение деталей заказа посредством звонка или отправки
              сообщения в мессенджеры</span
            ><span
              style="
                font-size: 12pt;
                font-family: 'Arial', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
                color: #212529;
                mso-fareast-language: RU;
              "
              ><o:p></o:p
            ></span>
          </p>

          <p
            class="MsoListParagraphCxSpLast ms-4"
            style="
              margin-bottom: 18pt;
              mso-add-space: auto;
              text-indent: -18pt;
              line-height: normal;
              mso-list: l1 level1 lfo3;
            "
          >
            <!--[if !supportLists]--><span
              style="
                font-size: 12pt;
                font-family: Symbol;
                mso-fareast-font-family: Symbol;
                mso-bidi-font-family: Symbol;
                color: #212529;
                mso-fareast-language: RU;
              "
              ><span style="mso-list: Ignore"
                >·<span style="font: 7pt 'Times New Roman'"
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span
              ></span
            ><!--[endif]--><span
              style="
                font-size: 12pt;
                mso-bidi-font-family: Calibri;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
              "
              >информирование Пользователя, посредством отправки электронных
              писем</span
            ><span
              style="
                font-size: 12pt;
                font-family: 'Arial', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
                color: #212529;
                mso-fareast-language: RU;
              "
              ><o:p></o:p
            ></span>
          </p>

          <h5>
            <span
              style="
                font-size: 12pt;
                line-height: 107%;
                font-family: 'Calibri', sans-serif;
                mso-ascii-theme-font: minor-latin;
                mso-hansi-theme-font: minor-latin;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
              "
              >5. Основанием для обработки персональных данных является: ст. 24
              Конституции Российской Федерации; ст.6 Федерального закона №152-ФЗ
              «О персональных данных»; Устав ООО Фирма Компьютер Сервис,
              настоящее согласие на обработку персональных данных<o:p></o:p
            ></span>
          </h5>

          <p class="MsoNormal">
            <span style="color: black; mso-themecolor: text1"
              ><o:p>&nbsp;</o:p></span
            >
          </p>

          <h5>
            <span
              style="
                font-size: 12pt;
                line-height: 107%;
                font-family: 'Calibri', sans-serif;
                mso-ascii-theme-font: minor-latin;
                mso-hansi-theme-font: minor-latin;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
              "
              >6. В ходе обработки с персональными данными будут совершены
              следующие действия: сбор; запись; систематизация; накопление;
              хранение; уточнение (обновление, изменение); извлечение;
              использование; передача (распространение, предоставление, доступ);
              блокирование; удаление; уничтожение.<o:p></o:p
            ></span>
          </h5>

          <p class="MsoNormal">
            <span style="color: black; mso-themecolor: text1"
              ><o:p>&nbsp;</o:p></span
            >
          </p>

          <h5>
            <span
              style="
                font-size: 12pt;
                line-height: 107%;
                font-family: 'Calibri', sans-serif;
                mso-ascii-theme-font: minor-latin;
                mso-hansi-theme-font: minor-latin;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
              "
              >7. Персональные данные обрабатываются до отписки физического лица
              от рекламных и новостных рассылок. Также обработка персональных
              данных может быть прекращена по запросу субъекта персональных
              данных. Хранение персональных данных, зафиксированных на бумажных
              носителях осуществляется согласно Федеральному закону №125-ФЗ «Об
              архивном деле в Российской Федерации» и иным нормативно правовым
              актам в области архивного дела и архивного хранения.<o:p></o:p
            ></span>
          </h5>

          <h5>
            <span
              style="
                font-size: 12pt;
                line-height: 107%;
                font-family: 'Calibri', sans-serif;
                mso-ascii-theme-font: minor-latin;
                mso-hansi-theme-font: minor-latin;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
                background: #fefefe;
              "
              >8. В&nbsp;случае выявления неточностей в&nbsp;персональных
              данных, Пользователь может актуализировать их&nbsp;самостоятельно,
              путем направления Оператору уведомление на&nbsp;адрес электронной
              почты Оператора </span
            ><span class="MsoHyperlink"
              ><span
                lang="EN-US"
                style="
                  font-size: 12pt;
                  line-height: 107%;
                  font-family: 'Calibri', sans-serif;
                  mso-ascii-theme-font: minor-latin;
                  mso-hansi-theme-font: minor-latin;
                  mso-bidi-theme-font: minor-latin;
                  background: #fefefe;
                  mso-ansi-language: EN-US;
                "
                ><a href="mailto:zakaz@pcs.ru"
                  >zakaz<span lang="RU" style="mso-ansi-language: RU">@</span
                  >pcs<span lang="RU" style="mso-ansi-language: RU">.</span
                  ><span class="SpellE">ru</span></a
                ></span
              ></span
            ><span
              style="
                font-size: 12pt;
                line-height: 107%;
                font-family: 'Calibri', sans-serif;
                mso-ascii-theme-font: minor-latin;
                mso-hansi-theme-font: minor-latin;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
                background: #fefefe;
              "
            >
              с&nbsp;пометкой «Актуализация персональных данных».</span
            ><span
              style="
                font-size: 12pt;
                line-height: 107%;
                font-family: 'Calibri', sans-serif;
                mso-ascii-theme-font: minor-latin;
                mso-hansi-theme-font: minor-latin;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
              "
              ><o:p></o:p
            ></span>
          </h5>

          <h5
            style="
              margin-top: 0cm;
              margin-right: 0cm;
              margin-bottom: 10pt;
              margin-left: 0cm;
            "
          >
            <span
              style="
                font-size: 12pt;
                line-height: 107%;
                font-family: 'Calibri', sans-serif;
                mso-ascii-theme-font: minor-latin;
                mso-hansi-theme-font: minor-latin;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
                background: #fefefe;
              "
              >9. Срок обработки персональных данных определяется достижением
              целей, для которых были собраны персональные данные, если иной
              срок не&nbsp;предусмотрен договором или действующим
              законодательством.<br />
              Пользователь может в&nbsp;любой момент отозвать свое согласие
              на&nbsp;обработку персональных данных, направив Оператору
              уведомление посредством электронной почты на&nbsp;электронный
              адрес Оператора </span
            ><span class="SpellE"
              ><span
                lang="EN-US"
                style="
                  font-size: 12pt;
                  line-height: 107%;
                  font-family: 'Calibri', sans-serif;
                  mso-ascii-theme-font: minor-latin;
                  mso-hansi-theme-font: minor-latin;
                  mso-bidi-theme-font: minor-latin;
                  color: black;
                  mso-themecolor: text1;
                  background: #fefefe;
                  mso-ansi-language: EN-US;
                "
                >zakaz</span
              ></span
            ><span
              style="
                font-size: 12pt;
                line-height: 107%;
                font-family: 'Calibri', sans-serif;
                mso-ascii-theme-font: minor-latin;
                mso-hansi-theme-font: minor-latin;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
                background: #fefefe;
              "
              >@</span
            ><span
              lang="EN-US"
              style="
                font-size: 12pt;
                line-height: 107%;
                font-family: 'Calibri', sans-serif;
                mso-ascii-theme-font: minor-latin;
                mso-hansi-theme-font: minor-latin;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
                background: #fefefe;
                mso-ansi-language: EN-US;
              "
              >pcs</span
            ><span
              style="
                font-size: 12pt;
                line-height: 107%;
                font-family: 'Calibri', sans-serif;
                mso-ascii-theme-font: minor-latin;
                mso-hansi-theme-font: minor-latin;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
                background: #fefefe;
              "
              >.</span
            ><span class="SpellE"
              ><span
                lang="EN-US"
                style="
                  font-size: 12pt;
                  line-height: 107%;
                  font-family: 'Calibri', sans-serif;
                  mso-ascii-theme-font: minor-latin;
                  mso-hansi-theme-font: minor-latin;
                  mso-bidi-theme-font: minor-latin;
                  color: black;
                  mso-themecolor: text1;
                  background: #fefefe;
                  mso-ansi-language: EN-US;
                "
                >ru</span
              ></span
            ><span
              style="
                font-size: 12pt;
                line-height: 107%;
                font-family: 'Calibri', sans-serif;
                mso-ascii-theme-font: minor-latin;
                mso-hansi-theme-font: minor-latin;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
                background: #fefefe;
              "
              >&nbsp;с&nbsp;пометкой «Отзыв согласия на&nbsp;обработку
              персональных данных».</span
            ><span
              style="
                font-size: 12pt;
                line-height: 107%;
                font-family: 'Calibri', sans-serif;
                mso-ascii-theme-font: minor-latin;
                mso-hansi-theme-font: minor-latin;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
              "
              ><o:p></o:p
            ></span>
          </h5>

          <h5>
            <span
              style="
                font-size: 12pt;
                line-height: 107%;
                font-family: 'Calibri', sans-serif;
                mso-ascii-theme-font: minor-latin;
                mso-hansi-theme-font: minor-latin;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
              "
              >10. В случае отзыва субъектом персональных данных или его
              представителем согласия на обработку персональных данных Оператор
              вправе продолжить обработку персональных данных без согласия
              субъекта персональных данных при наличии оснований, указанных в
              пунктах 2 - 11 части 1 статьи 6, части 2 статьи 10 и части 2
              статьи 11 Федерального закона №152-ФЗ «О персональных данных» от
              27.07.2006 г.<o:p></o:p
            ></span>
          </h5>

          <p class="MsoNormal">
            <span style="color: black; mso-themecolor: text1"
              ><o:p>&nbsp;</o:p></span
            >
          </p>

          <h5>
            <span
              style="
                font-size: 12pt;
                line-height: 107%;
                font-family: 'Calibri', sans-serif;
                mso-ascii-theme-font: minor-latin;
                mso-hansi-theme-font: minor-latin;
                mso-bidi-theme-font: minor-latin;
                color: black;
                mso-themecolor: text1;
              "
              >11. Настоящее согласие действует все время до момента прекращения
              обработки персональных данных, указанных в п.7 и п.9 данного
              Согласия.<o:p></o:p
            ></span>
          </h5>

          <p class="MsoNormal">
            <span
              style="
                font-size: 12pt;
                line-height: 107%;
                color: black;
                mso-themecolor: text1;
              "
              ><o:p>&nbsp;</o:p></span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { ref } from "vue";

export default {
  name: "Privacy",
  components: {},
  setup() {
    return {};
  },
};
</script>

<style></style>
