import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../components/Login.vue'
import Singup from '../components/Singup.vue'
import Chat from '../components/Chat.vue'
import Users from '../components/Users.vue'
import UsersTable from '../components/UsersTable.vue'
import Profile from '../components/Profile.vue'
import Support from '../components/Support.vue'
import Confluence from '../components/Confluence.vue'
import LoginByToken from '../components/LoginByToken.vue'
import Privacy from '../components/Privacy.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/login/:redirect?',
    props: true,
    name: 'login',
    component: Login,
  },
  {
    path: '/singup',
    name: 'singup',
    component: Singup,
  },
  {
    path: '/chat',
    name: 'chat',
    component: Chat,
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
  },
  {
    path: '/usersTable',
    name: 'usersTable',
    component: UsersTable,
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
  },
  {
    path: '/support',
    name: 'support',
    component: Support,
  },
  {
    path: '/confluence/:articleId?/:versionId?',
    props: true,
    name: 'confluence',
    component: Confluence,
  },
  {
    path: '/loginByToken/:access/:refresh',
    props: true,
    name: 'loginByToken',
    component: LoginByToken,
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
